<template>
	<div>
		<div v-if="inline === false" class="py-2">
			{{ item.name }}
		</div>
		<div v-else style="margin-top: 4px">
			<b-form-input
				v-model="item.name"
				type="text"
				class="mt-1"
				style="border: 1px solid #e9eaea; width: 300px"
				@blur="updateTitle"
				@keyup.enter.stop="updateTitle"
				autofocus
			/>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import ClickOutside from 'vue-click-outside';

export default {
	name: 'SubTaskInlineEdit',
	directives: {
		ClickOutside
	},
	mixins: [languageMessages],
	data() {
		return {
			inline: false
		};
	},
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		}
	},
	methods: {
		editInline() {
			this.inline = true;
		},
		async updateTitle() {
			this.inline = false;
			this.$emit('sub-task-inline:update-title', this.item);
		}
	}
};
</script>
