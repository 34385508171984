var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c(
        "div",
        { staticStyle: { color: "rgba(0, 0, 0, 0.6)" } },
        [
          _c("b-tree-view", {
            ref: "taskTreeView",
            attrs: {
              data: _vm.items,
              "context-menu": false,
              "context-menu-items": _vm.contextMenuItems,
            },
            on: { nodeSelect: _vm.nodeSelected },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }