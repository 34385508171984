var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-card-header",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "4", lg: "4" } }, [
                        _c("h4", [_vm._v(_vm._s(_vm.rendTitle))]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { md: "8", lg: "8" } },
                        [
                          _c(
                            "b-row",
                            {
                              class: `${
                                _vm.$screen.width >= 992
                                  ? "pull-right pr-2"
                                  : "pull-right"
                              }`,
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn-warning mr-2",
                                      attrs: {
                                        to: `/${_vm.pathPlanning}`,
                                        tag: "b-button",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-calendar",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t " +
                                          _vm._s(
                                            _vm.FormMSG(256, "Plannings")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      attrs: { variant: "success", size: "md" },
                                      on: { click: _vm.backToList },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(3, "Back to list")
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "icon-action-undo",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-tabs",
                    {
                      attrs: {
                        cards: "",
                        "active-nav-item-class":
                          "text-uppercase font-weight-bold",
                        "active-tab-class": "",
                        "title-item-class": "text-uppercase",
                        "content-class": "p-3",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(67, "Generals") } },
                        [
                          !_vm.isMyWorkSpace
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          attrs: {
                                            "no-body": "",
                                            "header-tag": "header",
                                            "footer-tag": "footer",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mr-2 text-success task-icon-card-header",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-share-alt",
                                                              attrs: {
                                                                "aria-hidden":
                                                                  "true",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-muted task-label-card-header",
                                                          },
                                                          [
                                                            _c(
                                                              "h6",
                                                              {
                                                                staticClass:
                                                                  "mb-0 font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      47,
                                                                      "Tree view tasks"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1352221213
                                          ),
                                        },
                                        [
                                          _c(
                                            "b-card-body",
                                            [
                                              _c("task-tree-view", {
                                                attrs: {
                                                  items: _vm.treeViewTasks,
                                                },
                                                on: {
                                                  "task-tree-view:select":
                                                    _vm.taskSelected,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "fieldset",
                            { staticClass: "scheduler-border" },
                            [
                              _c(
                                "legend",
                                { staticClass: "scheduler-border" },
                                [_vm._v(_vm._s(_vm.FormMSG(70, "Form")))]
                              ),
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            md: `${
                                              !_vm.isMyWorkSpace
                                                ? _vm.$screen.width <= 992
                                                  ? 12
                                                  : 10
                                                : 12
                                            }`,
                                            lg: `${
                                              !_vm.isMyWorkSpace
                                                ? _vm.$screen.width <= 992
                                                  ? 12
                                                  : 10
                                                : 12
                                            }`,
                                          },
                                        },
                                        [
                                          !_vm.isMyWorkSpace
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      77,
                                                      "N° order"
                                                    ),
                                                    "label-for": "order-number",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        78,
                                                        "Order Number..."
                                                      ),
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.task.orderNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "orderNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "task.orderNumber",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(16, "Name"),
                                                "label-for": "name",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.FormMSG(
                                                    4,
                                                    "Task name..."
                                                  ),
                                                  disabled: _vm.isMyWorkSpace,
                                                },
                                                model: {
                                                  value: _vm.task.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "task.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  17,
                                                  "Description"
                                                ),
                                                "label-for": "Description",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.FormMSG(
                                                    6,
                                                    "Task description..."
                                                  ),
                                                  rows: "16",
                                                  disabled: _vm.isMyWorkSpace,
                                                },
                                                model: {
                                                  value: _vm.task.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "task.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          !_vm.isMyWorkSpace
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      71,
                                                      "To Be Planned ?"
                                                    ),
                                                    "label-for": "ToPlan",
                                                    "label-cols": 3,
                                                    horizontal: true,
                                                  },
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      stacked: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.task.putInPlanning,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "putInPlanning",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "task.putInPlanning",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  21,
                                                  "Status"
                                                ),
                                                "label-for": "Status",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.statuses,
                                                  disabled: _vm.isMyWorkSpace,
                                                },
                                                model: {
                                                  value: _vm.task.status,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      "status",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "task.status",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  22,
                                                  "Priority"
                                                ),
                                                "label-for": "Priority",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.priorities,
                                                  disabled: _vm.isMyWorkSpace,
                                                },
                                                model: {
                                                  value: _vm.task.priority,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      "priority",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "task.priority",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  18,
                                                  "Customer"
                                                ),
                                                "label-for": "Customer",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("customer-selector", {
                                                attrs: {
                                                  "client-id":
                                                    _vm.task.clientId,
                                                  "location-id":
                                                    _vm.task.locationId,
                                                  "add-label": _vm.FormMSG(
                                                    37,
                                                    "Add"
                                                  ),
                                                  "update-label": _vm.FormMSG(
                                                    76,
                                                    "Update"
                                                  ),
                                                  placeholder: _vm.FormMSG(
                                                    32,
                                                    "Choose customer ..."
                                                  ),
                                                  "location-placeholder":
                                                    _vm.FormMSG(
                                                      75,
                                                      "Choose location ..."
                                                    ),
                                                  disabled: _vm.isMyWorkSpace,
                                                  "can-do-add":
                                                    !_vm.isMyWorkSpace,
                                                },
                                                on: {
                                                  "client:selected":
                                                    _vm.selectClient,
                                                  "client-location:selected":
                                                    _vm.selectLocation,
                                                  "client-location:removed":
                                                    _vm.onLocationRemoved,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(19, "Type"),
                                                "label-for": "Type",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c("task-type-selector", {
                                                attrs: {
                                                  "add-label": _vm.FormMSG(
                                                    38,
                                                    "Add"
                                                  ),
                                                  placeholder: _vm.FormMSG(
                                                    34,
                                                    "Choose type ..."
                                                  ),
                                                  "task-type-id":
                                                    _vm.task.taskTypeId,
                                                  disabled: _vm.isMyWorkSpace,
                                                  "can-do-add":
                                                    !_vm.isMyWorkSpace,
                                                },
                                                on: {
                                                  "task-type:selected":
                                                    _vm.selectType,
                                                  "task-type:clearSelection":
                                                    _vm.clearType,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  20,
                                                  "Duration"
                                                ),
                                                "label-for": "Duration",
                                                "label-cols": 3,
                                                horizontal: true,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "time",
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .duration &&
                                                                          _vm.task.duration
                                                                            .split(
                                                                              "T"
                                                                            )[1]
                                                                            .substring(
                                                                              0,
                                                                              5
                                                                            ),
                                                                        disabled:
                                                                          _vm.isMyWorkSpace,
                                                                      },
                                                                      on: {
                                                                        update:
                                                                          _vm.setDuration,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 task-label text-success font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              51,
                                                                              "Hours/d"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "number",
                                                                        step: "1",
                                                                        min: "0",
                                                                        placeholder:
                                                                          "1",
                                                                        disabled:
                                                                          _vm.isMyWorkSpace,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .dayDuration,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.task,
                                                                              "dayDuration",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "task.dayDuration",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 task-label text-success font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              50,
                                                                              "Days"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6", lg: "6" } },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "scheduler-border",
                                                    },
                                                    [
                                                      _c(
                                                        "legend",
                                                        {
                                                          staticClass:
                                                            "scheduler-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                59,
                                                                "Desired"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          staticClass:
                                                            "bootstrap-date-selection",
                                                          attrs: {
                                                            label: _vm.FormMSG(
                                                              58,
                                                              "Start Date"
                                                            ),
                                                            "label-for":
                                                              "DesiredDate",
                                                            "label-cols": 2,
                                                            horizontal: true,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  class: `${
                                                                    _vm.$screen
                                                                      .width <
                                                                    992
                                                                      ? "mb-1"
                                                                      : ""
                                                                  }`,
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      attrs: {
                                                                        "label-no-date-selected":
                                                                          _vm.FormMSG(
                                                                            31,
                                                                            "No date selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          _vm.isMyWorkSpace,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .desiredStart,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.task,
                                                                              "desiredStart",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "task.desiredStart",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-timepicker",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        required:
                                                                          "",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            72,
                                                                            "No time selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          _vm.isMyWorkSpace,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.desiredStartHour,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.desiredStartHour =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "desiredStartHour",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6", lg: "6" } },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "scheduler-border",
                                                    },
                                                    [
                                                      _c(
                                                        "legend",
                                                        {
                                                          staticClass:
                                                            "scheduler-border",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                60,
                                                                "Real"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          staticClass:
                                                            "bootstrap-date-selection",
                                                          attrs: {
                                                            label: _vm.FormMSG(
                                                              61,
                                                              "Start Date"
                                                            ),
                                                            "label-for":
                                                              "StartDate",
                                                            "label-cols": 2,
                                                            horizontal: true,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  class: `${
                                                                    _vm.$screen
                                                                      .width <
                                                                    992
                                                                      ? "mb-1"
                                                                      : ""
                                                                  }`,
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      attrs: {
                                                                        "label-no-date-selected":
                                                                          _vm.FormMSG(
                                                                            62,
                                                                            "No date selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .start,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.task,
                                                                              "start",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "task.start",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-timepicker",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        required:
                                                                          "",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            72,
                                                                            "No time selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.startHour,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.startHour =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "startHour",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          staticClass:
                                                            "bootstrap-date-selection",
                                                          attrs: {
                                                            label: _vm.FormMSG(
                                                              63,
                                                              "End Date"
                                                            ),
                                                            "label-for":
                                                              "EndDate",
                                                            "label-cols": 2,
                                                            horizontal: true,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  class: `${
                                                                    _vm.$screen
                                                                      .width <
                                                                    992
                                                                      ? "mb-1"
                                                                      : ""
                                                                  }`,
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      attrs: {
                                                                        "label-no-date-selected":
                                                                          _vm.FormMSG(
                                                                            64,
                                                                            "No date selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .end,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.task,
                                                                              "end",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "task.end",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    md: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                    lg: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-timepicker",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        required:
                                                                          "",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            72,
                                                                            "No time selected"
                                                                          ),
                                                                        locale:
                                                                          _vm.currentLang,
                                                                        disabled:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.endHour,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.endHour =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "endHour",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.isMyWorkSpace
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                md: `${
                                                  _vm.$screen.width <= 992
                                                    ? 12
                                                    : 2
                                                }`,
                                                lg: `${
                                                  _vm.$screen.width <= 992
                                                    ? 12
                                                    : 2
                                                }`,
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        type: "submit",
                                                        variant: "primary",
                                                        size: "md",
                                                        block: "",
                                                        disabled: _vm.isValid,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              12,
                                                              "Save"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        type: "button",
                                                        variant: "danger",
                                                        size: "md",
                                                        block: "",
                                                        disabled: _vm.isValid,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteTask(
                                                            _vm.task
                                                              .taskPlanningId,
                                                            _vm.task.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              79,
                                                              "Delete"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  !_vm.isMyWorkSpace
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-card",
                                                {
                                                  attrs: {
                                                    "no-body": "",
                                                    "header-tag": "header",
                                                    "footer-tag": "footer",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2 text-success task-icon-card-header",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-tasks",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-muted task-label-card-header",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h6",
                                                                      {
                                                                        staticClass:
                                                                          "mb-0 font-weight-bold",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              42,
                                                                              "Sub tasks"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "footer",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      md: "9",
                                                                      lg: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        class: {
                                                                          "is-invalid":
                                                                            _vm.subTaskSubmitted &&
                                                                            _vm
                                                                              .$v
                                                                              .subTask
                                                                              .name
                                                                              .$error,
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            height:
                                                                              "35px",
                                                                          },
                                                                        attrs: {
                                                                          type: "text",
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              43,
                                                                              "Title sub task..."
                                                                            ),
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .$v
                                                                              .subTask
                                                                              .name
                                                                              .$model,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .$v
                                                                                  .subTask
                                                                                  .name,
                                                                                "$model",
                                                                                typeof $$v ===
                                                                                  "string"
                                                                                  ? $$v.trim()
                                                                                  : $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "$v.subTask.name.$model",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm.subTaskSubmitted &&
                                                                    !_vm.$v
                                                                      .subTask
                                                                      .name
                                                                      .required
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "invalid-feedback",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    45,
                                                                                    "Sub task title is required"
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? "mt-2"
                                                                        : ""
                                                                    }`,
                                                                    attrs: {
                                                                      md: "3",
                                                                      lg: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      {
                                                                        staticClass:
                                                                          "my-0",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            staticClass:
                                                                              "py-2 tags-assigned",
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                                variant:
                                                                                  "outline-primary",
                                                                                size: "md",
                                                                                block:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.addSubTask,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      44,
                                                                                      "Add"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2094320858
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "b-card-body",
                                                    [
                                                      _c("sub-task", {
                                                        attrs: {
                                                          items: _vm.subTasks,
                                                        },
                                                        on: {
                                                          "sub-task:removed":
                                                            _vm.removeSubTask,
                                                          "sub-task:titleUpdated":
                                                            _vm.updateSubTask,
                                                          "sub-task:updateStatus":
                                                            _vm.updateStatus,
                                                          "sub-task:updatePriority":
                                                            _vm.updatePriority,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mr-2 text-success task-icon-card-header",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-user",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-muted task-label-card-header",
                                                              },
                                                              [
                                                                _c(
                                                                  "h6",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 font-weight-bold",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          28,
                                                                          "Assigned users"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  !_vm.isMyWorkSpace &&
                                                  _vm.canManagePlanning
                                                    ? {
                                                        key: "footer",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "task-user-selector",
                                                                      {
                                                                        attrs: {
                                                                          "assigned-to":
                                                                            _vm
                                                                              .task
                                                                              .assignedTo,
                                                                        },
                                                                        on: {
                                                                          "task-user-selector:userAdded":
                                                                            _vm.assignedNewUser,
                                                                          "task-user-selector:userDeleted":
                                                                            _vm.removeUserAssignee,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c("task-assigned-user", {
                                                    attrs: {
                                                      items: _vm.users,
                                                      col: `${
                                                        _vm.$screen.width < 992
                                                          ? 12
                                                          : 4
                                                      }`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "header",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mr-2 text-success task-icon-card-header",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-users",
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-muted task-label-card-header",
                                                              },
                                                              [
                                                                _c(
                                                                  "h6",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 font-weight-bold",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          30,
                                                                          "Assigned teams"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  !_vm.isMyWorkSpace &&
                                                  _vm.canManagePlanning
                                                    ? {
                                                        key: "footer",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      md: "9",
                                                                      lg: "9",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "task-team-selector",
                                                                      {
                                                                        attrs: {
                                                                          "assigned-team":
                                                                            _vm
                                                                              .task
                                                                              .assignedTeam,
                                                                        },
                                                                        on: {
                                                                          "task-team-selector:teamAdded":
                                                                            _vm.assignedNewTeam,
                                                                          "task-team-selector:teamDeleted":
                                                                            _vm.removeTeamAssignee,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    class: `${
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? "mt-2"
                                                                        : ""
                                                                    }`,
                                                                    attrs: {
                                                                      md: "3",
                                                                      lg: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      {
                                                                        staticClass:
                                                                          "my-0",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            staticClass:
                                                                              "py-2 px-4 tags-assigned",
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                                variant:
                                                                                  "outline-primary",
                                                                                size: "md",
                                                                                block:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.openAssignationTeamModal,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      29,
                                                                                      "Add"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c("task-assigned-team", {
                                                    attrs: {
                                                      items: _vm.teams,
                                                      col: `${
                                                        _vm.$screen.width < 992
                                                          ? 12
                                                          : 4
                                                      }`,
                                                      "can-do-edit":
                                                        !_vm.isMyWorkSpace &&
                                                        _vm.canManagePlanning,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(68, "Documents") } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-files-o",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        25,
                                                                        "Documents"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c("b-col", {
                                                            attrs: {
                                                              md: "9",
                                                              lg: "9",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "3",
                                                                lg: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openDocumentModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                14,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "green-film-docs",
                                                            {
                                                              ref: "cardDocumentList",
                                                              attrs: {
                                                                GFId: _vm.id,
                                                                "show-input-file": false,
                                                                GFType: "task",
                                                                GFSubType:
                                                                  "documents",
                                                              },
                                                              on: {
                                                                "el:updated":
                                                                  _vm.greenFilmDocsMounted,
                                                              },
                                                            }
                                                          ),
                                                          _vm.elDocMounted ===
                                                            true &&
                                                          _vm.greenFilmDocLength ===
                                                            0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center empty-item-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          74,
                                                                          "No document selected"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: { "no-body": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-image",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        24,
                                                                        "Images"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c("b-col", {
                                                            attrs: {
                                                              md: "9",
                                                              lg: "9",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "3",
                                                                lg: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.togglePictureModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                13,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _vm.task.images.length === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          ref: "empty-image",
                                                          staticClass:
                                                            "text-center empty-item-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  52,
                                                                  "No image selected"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _c("ImgShowroomTable", {
                                                        attrs: {
                                                          "img-data":
                                                            _vm.images,
                                                        },
                                                        on: {
                                                          "image:removed":
                                                            _vm.imageRemoved,
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(69, "Materials") } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: { "no-body": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-shopping-cart",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        53,
                                                                        "Materials"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c("b-col", {
                                                            attrs: {
                                                              md: "9",
                                                              lg: "9",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "3",
                                                                lg: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "my-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "py-2 px-4 tags-assigned",
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "md",
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.addNewMaterial,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                56,
                                                                                "Add"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c("task-material-list", {
                                                    ref: "task-material-list",
                                                    attrs: {
                                                      "task-id": _vm.id,
                                                    },
                                                    on: {
                                                      "task-material:reloadData":
                                                        _vm.reloadMaterialList,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: _vm.FormMSG(73, "Comments") },
                          on: { click: _vm.tabComment },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              attrs: {
                                                "no-body": "",
                                                "header-tag": "header",
                                                "footer-tag": "footer",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mr-2 text-success task-icon-card-header",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-exchange",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-muted task-label-card-header",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        26,
                                                                        "Comments"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c("task-editor", {
                                                        attrs: {
                                                          "task-xid": _vm.xid,
                                                          "reply-to": 0,
                                                          "image-parent-id":
                                                            parseInt(
                                                              _vm.task.rootId,
                                                              10
                                                            ) === 0
                                                              ? parseInt(
                                                                  _vm.task.id,
                                                                  10
                                                                )
                                                              : parseInt(
                                                                  _vm.task
                                                                    .rootId,
                                                                  10
                                                                ),
                                                        },
                                                        on: {
                                                          "task-comment:added":
                                                            _vm.commentAdded,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            },
                                            [
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c("task-comment", {
                                                    attrs: {
                                                      "key-value": {
                                                        xid: _vm.xid,
                                                        imageParentId:
                                                          parseInt(
                                                            _vm.task.rootId,
                                                            10
                                                          ) === 0
                                                            ? parseInt(
                                                                _vm.task.id,
                                                                10
                                                              )
                                                            : parseInt(
                                                                _vm.task.rootId,
                                                                10
                                                              ),
                                                      },
                                                      "new-comment":
                                                        _vm.newComment,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("task-document-modal", {
                        attrs: { "task-id": _vm.id },
                        on: { "task-document:hidden": _vm.onHideDocumentModal },
                        model: {
                          value: _vm.isOpenDocumentModal,
                          callback: function ($$v) {
                            _vm.isOpenDocumentModal = $$v
                          },
                          expression: "isOpenDocumentModal",
                        },
                      }),
                      _c("task-material-modal", {
                        attrs: { material: _vm.material },
                        on: {
                          "task-material-modal:cancel":
                            _vm.onCancelMaterialModal,
                          "task-material-modal:taskMaterialUpdated":
                            _vm.onTaskMaterialUpdated,
                        },
                        model: {
                          value: _vm.isOpenMaterialModal,
                          callback: function ($$v) {
                            _vm.isOpenMaterialModal = $$v
                          },
                          expression: "isOpenMaterialModal",
                        },
                      }),
                      _c("capture", {
                        attrs: {
                          "multiple-img": "",
                          "dynamic-crop-size": "",
                          "edit-data": _vm.task,
                          "parent-id": _vm.id,
                          "parent-type": "task",
                          "parent-sub-type": "images",
                        },
                        on: { change: _vm.handleImgsTaked },
                        model: {
                          value: _vm.isCaptureModalOpen,
                          callback: function ($$v) {
                            _vm.isCaptureModalOpen = $$v
                          },
                          expression: "isCaptureModalOpen",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }