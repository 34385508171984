var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "my-0",
      attrs: { variant: "link", "no-caret": "", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "sub-task-nav-update",
                  attrs: { id: `update-action-${_vm.index}` },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-ellipsis-v",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: `update-action-${_vm.index}`,
                        placement: "topleft",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(3, "Actions")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        {
          attrs: { action: "" },
          on: {
            click: function ($event) {
              return _vm.updateTitle(_vm.item)
            },
          },
        },
        [
          _c("div", { staticClass: "sub-task-dropdown-item-action" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", {
                staticClass: "fa fa-bars update-title",
                attrs: { "aria-hidden": "true" },
              }),
            ]),
            _c("div", { staticClass: "sub-task-text label" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.FormMSG(4, "Update title")) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ]
      ),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          attrs: { action: "" },
          on: {
            click: function ($event) {
              return _vm.deleteTask(_vm.item.id)
            },
          },
        },
        [
          _c("div", { staticClass: "sub-task-dropdown-item-action" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", {
                staticClass: "fa fa-trash remove",
                attrs: { "aria-hidden": "true" },
              }),
            ]),
            _c("div", { staticClass: "sub-task-text label" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(6, "Remove")) + "\n\t\t\t"
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }