<template>
	<div class="m-0">
		<!-- <pre>{{ colorStatuses }}</pre> -->
		<div v-if="items.length === 0" ref="empty-sub-tasks" class="text-center empty-item-text">
			{{ FormMSG(1, 'No sub task') }}
		</div>
		<div v-else class="m-0">
			<div v-for="(item, i) in items" :key="i" class="row">
				<div class="col-12">
					<ul class="list-sub-task row pt-0">
						<li class="px-2">
							<div class="d-flex flex-row">
								<div class="p-0">
									<b-dropdown variant="link" class="my-0" no-caret left>
										<template #button-content>
											<div :id="`img-status-${i}`" class="sub-task-nav-status">
												<b-img
													:blank-color="`${'#' + base16ToHexa(statuses[item.status].factor)}`"
													width="26"
													height="26"
													class="cursor"
													blank
												/>
											</div>
											<b-tooltip :target="`img-status-${i}`" placement="topright">
												{{ statuses[item.status].text }}
											</b-tooltip>
										</template>
										<b-dropdown-item
											v-for="(colorStatus, iStatus) in statuses"
											:key="iStatus"
											:active="item.status === colorStatus.value"
											action
											@click="changeStatus(item, colorStatus.value)"
										>
											<div class="sub-task-text sub-task-dropdown-item-img">
												<b-img
													:blank-color="`${'#' + base16ToHexa(colorStatus.factor)}`"
													width="22"
													height="22"
													rounded="circle"
													blank
												/>
												<span class="ml-3">{{ colorStatus.text }}</span>
											</div>
										</b-dropdown-item>
									</b-dropdown>
								</div>
								<div class="p-0 mr-2">
									<b-dropdown variant="link" class="my-0" no-caret left>
										<template #button-content>
											<div
												:id="`flag-priority-${i}`"
												class="sub-task-nav-priority"
												:style="`color: #${base16ToHexa(priorities[item.priority].factor)}`"
											>
												<i class="fa fa-flag" aria-hidden="true" />
											</div>
											<b-tooltip :target="`flag-priority-${i}`" placement="topright">
												{{ priorities[item.priority].text }}
											</b-tooltip>
										</template>
										<b-dropdown-item
											v-for="(colorPriority, iPriority) in priorities"
											:key="iPriority"
											:active="item.priority === colorPriority.value"
											action
											@click="changePriority(item, colorPriority.value)"
										>
											<div class="sub-task-text sub-task-dropdown-item-img">
												<b-img
													:blank-color="`${'#' + base16ToHexa(colorPriority.factor)}`"
													width="22"
													height="22"
													rounded="circle"
													blank
												/>
												<span class="ml-3">{{ colorPriority.text }}</span>
											</div>
										</b-dropdown-item>
									</b-dropdown>
								</div>
								<div class="sub-task-text mr-3">
									<sub-task-inline-edit
										:ref="`inline-edit-${item.id}`"
										:item="item"
										@sub-task-inline:update-title="updateTitleSubTaskInline"
									/>
								</div>
								<div class="p-0">
									<sub-task-context-menu
										:index="i"
										:item="item"
										@sub-task:removed="deleteSubTask"
										@sub-task:update-title="updateTitleSubTask"
									/>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { updatedTask } from '@/cruds/task.crud';
import * as _ from 'lodash';
import SubTaskContextMenu from '@/components/Task/SubTaskContextMenu';
import SubTaskInlineEdit from '@/components/Task/SubTaskInlineEdit';

export default {
	name: 'SubTask',
	components: {
		SubTaskContextMenu,
		SubTaskInlineEdit
	},
	mixins: [languageMessages, globalMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {};
	},
	async mounted() {},
	computed: {
		statuses() {
			const statuses = this.FormMenu(1122);
			return statuses.sort((a, b) => a.value - b.value);
		},
		priorities() {
			const priorities = this.FormMenu(1123);
			return priorities.sort((a, b) => a.value - b.value);
		}
	},
	methods: {
		changeStatus(task, value) {
			// console.log('changeStatus: ', {task, value});
			this.$emit('sub-task:updateStatus', task, value);
		},
		changePriority(task, value) {
			// console.log('changePriority: ', {task, value});
			this.$emit('sub-task:updatePriority', task, value);
		},
		async deleteSubTask() {
			this.$emit('sub-task:removed', true);
		},
		updateTitleSubTask(id) {
			this.$refs['inline-edit-' + id][0].editInline();
		},
		async updateTitleSubTaskInline(item) {
			// console.log('update title: ', item);
			await updatedTask(item.id, {
				name: item.name,
				taskPlanningId: item.taskPlanningId
			}).then((id) => {
				if (id) {
					this.createToastForMobile(this.FormMSG(3, 'Success'), this.FormMSG(2, 'Title sub task updated successfully'), '', 'success');

					this.$emit('sub-task:titleUpdated', true);
				}
			});
		}
	}
};
</script>
