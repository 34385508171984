var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-0" }, [
    _vm.items.length === 0
      ? _c(
          "div",
          {
            ref: "empty-sub-tasks",
            staticClass: "text-center empty-item-text",
          },
          [_vm._v("\n\t\t" + _vm._s(_vm.FormMSG(1, "No sub task")) + "\n\t")]
        )
      : _c(
          "div",
          { staticClass: "m-0" },
          _vm._l(_vm.items, function (item, i) {
            return _c("div", { key: i, staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("ul", { staticClass: "list-sub-task row pt-0" }, [
                  _c("li", { staticClass: "px-2" }, [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        { staticClass: "p-0" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "my-0",
                              attrs: {
                                variant: "link",
                                "no-caret": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sub-task-nav-status",
                                            attrs: { id: `img-status-${i}` },
                                          },
                                          [
                                            _c("b-img", {
                                              staticClass: "cursor",
                                              attrs: {
                                                "blank-color": `${
                                                  "#" +
                                                  _vm.base16ToHexa(
                                                    _vm.statuses[item.status]
                                                      .factor
                                                  )
                                                }`,
                                                width: "26",
                                                height: "26",
                                                blank: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: `img-status-${i}`,
                                              placement: "topright",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.statuses[item.status].text
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            _vm._l(
                              _vm.statuses,
                              function (colorStatus, iStatus) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: iStatus,
                                    attrs: {
                                      active: item.status === colorStatus.value,
                                      action: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeStatus(
                                          item,
                                          colorStatus.value
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sub-task-text sub-task-dropdown-item-img",
                                      },
                                      [
                                        _c("b-img", {
                                          attrs: {
                                            "blank-color": `${
                                              "#" +
                                              _vm.base16ToHexa(
                                                colorStatus.factor
                                              )
                                            }`,
                                            width: "22",
                                            height: "22",
                                            rounded: "circle",
                                            blank: "",
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(_vm._s(colorStatus.text)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "p-0 mr-2" },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "my-0",
                              attrs: {
                                variant: "link",
                                "no-caret": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sub-task-nav-priority",
                                            style: `color: #${_vm.base16ToHexa(
                                              _vm.priorities[item.priority]
                                                .factor
                                            )}`,
                                            attrs: { id: `flag-priority-${i}` },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-flag",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: `flag-priority-${i}`,
                                              placement: "topright",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.priorities[item.priority]
                                                    .text
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            _vm._l(
                              _vm.priorities,
                              function (colorPriority, iPriority) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: iPriority,
                                    attrs: {
                                      active:
                                        item.priority === colorPriority.value,
                                      action: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changePriority(
                                          item,
                                          colorPriority.value
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sub-task-text sub-task-dropdown-item-img",
                                      },
                                      [
                                        _c("b-img", {
                                          attrs: {
                                            "blank-color": `${
                                              "#" +
                                              _vm.base16ToHexa(
                                                colorPriority.factor
                                              )
                                            }`,
                                            width: "22",
                                            height: "22",
                                            rounded: "circle",
                                            blank: "",
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(_vm._s(colorPriority.text)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "sub-task-text mr-3" },
                        [
                          _c("sub-task-inline-edit", {
                            ref: `inline-edit-${item.id}`,
                            refInFor: true,
                            attrs: { item: item },
                            on: {
                              "sub-task-inline:update-title":
                                _vm.updateTitleSubTaskInline,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "p-0" },
                        [
                          _c("sub-task-context-menu", {
                            attrs: { index: i, item: item },
                            on: {
                              "sub-task:removed": _vm.deleteSubTask,
                              "sub-task:update-title": _vm.updateTitleSubTask,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }