<template>
	<errorContainer :error="error" :warning="warning">
		<div style="color: rgba(0, 0, 0, 0.6)">
			<b-tree-view ref="taskTreeView" :data="items" :context-menu="false" :context-menu-items="contextMenuItems" @nodeSelect="nodeSelected" />
		</div>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'TaskTreeView',
	mixins: [languageMessages],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			contextMenuItems: [
				{
					code: 'EDIT_TASK',
					label: 'Edit Task'
				}
			],
			error: {},
			warning: ''
		};
	},
	methods: {
		nodeSelected(node, isSelected) {
			if (isSelected) {
				this.$emit('task-tree-view:select', node.data);
			}
		}
	}
};
</script>
