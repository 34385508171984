var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.inline === false
      ? _c("div", { staticClass: "py-2" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.item.name) + "\n\t"),
        ])
      : _c(
          "div",
          { staticStyle: { "margin-top": "4px" } },
          [
            _c("b-form-input", {
              staticClass: "mt-1",
              staticStyle: { border: "1px solid #e9eaea", width: "300px" },
              attrs: { type: "text", autofocus: "" },
              on: {
                blur: _vm.updateTitle,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                  return _vm.updateTitle.apply(null, arguments)
                },
              },
              model: {
                value: _vm.item.name,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "name", $$v)
                },
                expression: "item.name",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }